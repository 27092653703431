<template>
  <div class="d-flex align-center" style="height: 100vh">
    <v-card class="relative mx-auto">
      <v-card-title>Telepítő</v-card-title>
      <v-divider/>
      <v-card-text class="d-flex">
        <div class="ma-3">
          <v-text-field label="Adatbázis url" value="localhost:3306" filled />
          <v-text-field label="Adatbázis név" value="belepteto" filled />
          <v-text-field label="Adatbázis felhasználó" type="admin" value="root" filled />
          <v-text-field label="Adatbázis jelszó" type="password" filled />
        </div>
        <div class="ma-3">
          <v-checkbox label="HikVision modul"/>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="success" large>
          <v-icon left>mdi-check-bold</v-icon>
          Telepítés
        </v-btn>
        <v-spacer/>
        <v-btn color="warning" large>
          <v-icon left>mdi-check-bold</v-icon>
          Javítás
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
